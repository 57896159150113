<template>
  <div class="store-header-box">
    <div class="center-section">
      <!-- 左边公司名跟logo -->
      <div class="left">
        <router-link v-if="!basicInformation.id" :to="{path:'/'}">
          <img class="com-logo" src="@/assets/img/logo.png" alt="logo">
        </router-link>
        <img v-else-if="basicInformation.shopLogo" class="com-logo" :src="$fileUrl+basicInformation.shopLogo" alt="logo">
        <img v-else class="com-logo" src="@/assets/storeManagement/image/store_logo.png" alt="logo">
        <div class="product-name" @mouseover="showPop" @mouseleave="isShow=false">
          <!-- 公司名字 -->
          <div class="name">
            <h3 class="elp-1">
              {{ basicInformation.cmpName||'小链科技' }}
            </h3>
            <i v-if="basicInformation.id" :class="['icon', 'el-icon-caret-bottom',isShow?'rotate':'']" />
          </div>
          <!-- 是否认证 -->
          <div v-if="basicInformation.id" class="authentication-status">
            <!-- 已认证 -->
            <img
              v-if="basicInformation.authState!=='0'"
              class="authentication"
              src="@/assets/storeManagement/icon/authentication.png"
              alt="icon"
            >
            <!-- 年数 -->
            <img
              class="cooperation"
              src="@/assets/storeManagement/icon/cooperation.png"
              alt="icon"
            >
            <span>优选拍档{{ basicInformation.openShopDate }}年</span>
          </div>
          <!-- 点击公司名的弹出层啊  -->
          <div v-show="isShow" ref="section" class="popups-box">
            <div class="item center">
              <span>入驻时长：</span>
              <p><img src="@/assets/storeManagement/icon/duration.png" alt="">{{ basicInformation.openShopDate }}年</p>
            </div>
            <div class="item center">
              <span>档案：</span>
              <p @click="archivalInformationPop = true">
                <img src="@/assets/storeManagement/icon/record.png" alt="">企业档案
              </p>
            </div>
            <div class="item">
              <span>所在地区：</span>
              <p>{{ basicInformation.placeProvinceName + basicInformation.placeCityName + basicInformation.placeRegionName }}</p>
            </div>
            <div class="item">
              <span>业务类型：</span>
              <p>{{ businessType }}</p>
            </div>
            <div class="item">
              <span>业务咨询：</span>
              <p v-if="token">
                {{ basicInformation.contactPerson && basicInformation.contactPerson.length > 0 ? basicInformation.contactPerson.substr(0, 1) : '' }}经理&nbsp;&nbsp;{{ basicInformation.contactPhone }}
              </p>
              <p v-else @click="skipCart">
                <img src="@/assets/storeManagement/icon/phone_one.png" alt="">联系TA
              </p>
            </div>
            <div class="item">
              <span>营业时间：</span>
              <div>
                <div>
                  工作日：<i v-if="basicInformation.tradeShopOpenTime&&basicInformation.tradeShopOpenTime.workOpenTime">{{ basicInformation.tradeShopOpenTime.workOpenTime }}-{{ basicInformation.tradeShopOpenTime.workCloseTime }}</i>
                </div>
                <div>节假日：<i v-if="basicInformation.tradeShopOpenTime&&basicInformation.tradeShopOpenTime.weekdayOpenTime">{{ basicInformation.tradeShopOpenTime.weekdayOpenTime }}-{{ basicInformation.tradeShopOpenTime.weekdayCloseTime }}</i></div>
              </div>
            </div>
            <router-link class="enter-shop" :to="{path:'/storeManagement/storeHomepage',query:{shopId:basicInformation.id}}">
              进入店铺
            </router-link>
          </div>
        </div>
      </div>
      <div class="right">
        <div v-if="$route.path === '/spotHall/spotHallDetail'" class="search">
          <div class="input-with-select">
            <el-input
              v-model="keyword"
              type="text"
              placeholder="请输入商品名称/品类/规格/型号"
            >
              <span slot="append" class="search-btn" @click="searchGoods">
                搜 索
              </span>
            </el-input>
          </div>
          <div
            class="car-box"
            @click="skipCart"
          >
            <img
              :style="[cartNum > 0 ? 'margin-right:24px' : '']"
              src="@/assets/icon/cat2.png"
              alt="cat"
            >
            <span>购物车</span>
            <i v-if="cartNum > 0">{{ cartNum > 99 ? "99+" : cartNum }}</i>
          </div>
        </div>
        <div v-else class="search">
          <div class="input-with-select">
            <el-input
              v-model="keyword"
              type="text"
              placeholder="请输入商品名称/品类/规格/型号"
            >
              <span slot="append" class="search-btn" @click="searchOurStore">
                搜本店
              </span>
            </el-input>
          </div>
          <button class="search-station" @click="searchGoods">
            搜全站
          </button>
          <div
            class="car-box"
            @click="skipCart"
          >
            <img
              :style="[cartNum > 0 ? 'margin-right:24px' : '']"
              src="@/assets/icon/cat2.png"
              alt="cat"
            >
            <span>购物车</span>
            <i v-if="cartNum > 0">{{ cartNum > 99 ? "99+" : cartNum }}</i>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="工商信息" width="560px" :visible.sync="archivalInformationPop">
      <el-descriptions title="" :column="1">
        <el-descriptions-item label="注册资本">
          {{ basicInformation.cmpRegFund||0 }}万元
        </el-descriptions-item>
        <el-descriptions-item label="注册地址">
          {{ basicInformation.cmpAddress }}
        </el-descriptions-item>
        <el-descriptions-item label="成立日期">
          {{ basicInformation.cmpDate }}
        </el-descriptions-item>
        <el-descriptions-item label="登记机关">
          {{ basicInformation.cmpRegOrg }}
        </el-descriptions-item>
        <el-descriptions-item label="营业期限">
          {{ basicInformation.cmpCloseDate }}
        </el-descriptions-item>
        <el-descriptions-item label="统一社会信用代码">
          {{ basicInformation.cmpUnicode }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SmallHeader',
  props: {
    basicInformation: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      keyword: '',
      cartNum: 0,
      token: null,
      businessType: '',
      isShow: false,
      archivalInformationPop: false
    }
  },
  computed: {
    ...mapState(['carNum'])
  },
  watch: {
    basicInformation: {
      handler(newVal) {
        const businessType = this.$store.getters.getDictionaryItem('SHOP_BUSINESS_TYPE') || []
        newVal.tradeShopBusinessTypeList.forEach((val, index) => {
          const obj = businessType.find(item => item.dictId === val.type) || {}
          this.businessType += ((index > 0 ? '、' : '') + obj.dictName)
        })
      }
    },
    carNum: {
      handler(newVal) {
        this.cartNum = newVal
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    if (this.$route.query.keyword) this.keyword = this.$route.query.keyword
    document.addEventListener('mouseleave', (e) => {
      this.$nextTick(() => {
        if (this.$refs.section && !this.$refs.section.contains(e.target)) this.isShow = false
      })
    })
    this.token = localStorage.getItem(
      `mallFrontDeskToken${this.$store.state.userCmpRole}`
    )
  },
  methods: {
    showPop() {
      if (this.basicInformation.id) this.isShow = true
    },
    // 搜索本店
    searchOurStore() {
      if (this.$route.path === '/storeManagement/ourStoreGoods') {
        this.$emit('getKeyword', this.keyword)
        return
      }
      this.$router.push({
        path: '/storeManagement/ourStoreGoods',
        query: { cmpCode: this.basicInformation.cmpCode, shopId: this.basicInformation.id, keyword: this.keyword }
      })
    },
    // 点击搜索跳转
    searchGoods() {
      if (this.$route.path !== '/spotHall/index') {
        const routeUrl = this.$router.resolve({
          path: '/spotHall/index',
          query: { goodsType: '0', keyword: this.keyword }
        })
        window.open(routeUrl.href, '_blank')
        return
      }
      this.$router.replace({
        query: {
          keyword: this.keyword,
          goodsType: this.$route.query.goodsType,
          goodsName: this.$route.query.goodsName,
          code: this.$route.query.code
        }
      })
    },
    // 跳转购物车
    async skipCart() {
      if (!this.token || !this.token.length) {
        const confirm = await this.$confirm('未登录，是否立即登录?', '提示', {
          type: 'warning'
        }).catch((err) => err)
        if (confirm !== 'confirm') return
        this.$router.push('/login')
        return
      }
      this.$router.push({ path: '/shoppingCart/index' })
    }
  }
}
</script>

<style lang="scss" scoped>
.store-header-box {
  width: 100%;
  height: 114px;
  background-color: #fff;
  box-sizing: border-box;
  border-bottom: 1px solid #efefef;
  z-index: 22;
  /deep/.el-dialog{
    .el-descriptions-item__container{
      display: flex;
      align-items: center;
      >span{
        flex: 0 0 auto;
        color: #000000;
        font-size: 14px;
        font-weight: 400;
      }
      .el-descriptions-item__label {
        color: #666666;
        margin-right: 8px;
      }
    }
    .el-dialog__header{
      border-bottom: 1px solid #E7E7E7;
      padding: 20px 24px 20px 32px;
      .el-dialog__title{
        color: #000000;
        font-size: 20px;
        font-weight: 500;
      }
      .el-dialog__close{
        font-size: 24px;
      }
    }
  }
  .center-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: $centerPlateWidth;
    margin: auto;
    height: 100%;
    .left {
      display: flex;
      align-items: center;
      .product-name {
        position: relative;
        height: 82px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .popups-box{
            position: absolute;
            top: 76px;
            left: 50%;
            transform: translateX(-50%);
            width: 298px;
            padding: 16px 20px;
            background-color: #fff;
            transition: all 5s;
            box-shadow: 0 2px 15px 0 rgba(0,0,0,.3);
            transition: all .5s;
            z-index: 20;
            .enter-shop{
                display: inline-block;
                height: 32px;
                line-height: 32px;
                text-align: center;
                width: 99px;
                border: 1px solid #E1E1E1;
                margin-top: 16px;
                outline: 0;
                background-color: #fff;
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                transform: translateX(100px);
            }
            .item{
                display: flex;
                align-items: flex-start;

                font-size: 14px;
                color: #999999;
                padding-bottom: 16px;
                span{
                    flex: 0 0 82px;
                }
                >div,p{
                    color: #333333;
                    display: flex;
                    i{
                      font-style: normal;
                    }
                    img{
                        height: 16px;
                        width: 16px;
                        margin-right: 4px;
                    }
                }
                >div{
                  display: block;
                }
            }
            .center{
              align-items: center;
              cursor: pointer;
            }
            .item:last-of-type{
                border-bottom: 1px solid #EFEFEF;
            }
        }
        .name {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          cursor: pointer;
          .icon {
            height: 16px;
            width: 16px;
            color: #999;
            margin-left: 4px;
            transition: all .5s;
          }
          .rotate{
            transform: rotate(180deg);
          }
          h3 {
            color: #000000;
            font-size: 20px;
            font-weight: 500;
            max-width: 280px;
          }
        }
        .authentication-status {
          display: flex;
          align-items: center;
          color: #333333;
          font-size: 12px;
          font-weight: 400;
          .authentication {
            width: 60px;
            height: 20px;
            margin-right: 8px;
          }
          .cooperation {
            height: 20px;
            width: 20px;
            margin-right: 2px;
          }
        }
      }
      .com-logo {
        height: 82px;
        width: 82px;
        margin-right: 20px;
      }
    }
    .right {
      .search {
        display: flex;
        align-items: center;
        margin-left: 42px;
        margin-right: 16px;
        .car-box {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 8px;
          flex: 0 0 112px;
          height: 44px;
          padding: 0 16px;
          // border-radius: 2px;
          border: 1px solid #e7e7e7;
          box-sizing: border-box;
          cursor: pointer;
          color: $fontColor5;
          font-size: 16px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 6px;
          }

          i {
            position: absolute;
            left: 6px;
            top: 6px;
            font-style: normal;
            text-align: center;
            display: inline-block;
            width: 20px;
            height: 14px;
            line-height: 14px;
            font-size: 12px;
            color: #fff;
            background-color: $themeColors;
            border-radius: 49px;
          }
        }
        .search-station {
          height: 44px;
          width: 80px;
          border: 1px solid #d31e0e;
          color: #d31e0e;
          font-size: 16px;
          margin-left: 4px;
          background-color: #fff;
          outline: 0;
        }
        .input-with-select {
          width: 410px;
          height: 42px;
          border: 1px solid $themeColors;
          /deep/ .el-input-group__append,
          /deep/.el-input-group__prepend {
            border-radius: 0;
            border: none;
            background-color: rgba(0, 0, 0, 0);
          }
          /deep/.el-input-group__prepend {
            padding: 0;
            width: 64px;
            position: relative;
            .el-select {
              margin: 0;
              font-size: 14px;
              .el-input__inner {
                padding-right: 0;
                padding-left: 16px;
              }
            }
            &::after {
              content: "";
              width: 1px;
              height: 20px;
              background-color: #d9d9d9;
              position: absolute;
              right: 0;
              top: 12px;
            }
          }
          /deep/ .el-input-group__append {
            background-color: $themeColors;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
            padding: 0;
            span {
              display: inline-block;
              width: 100%;
              height: 100%;
              padding: 10px 24px;
              box-sizing: border-box;
            }
          }
          /deep/ .el-input__inner {
            border: none;
            height: 42px;
          }
        }
      }
    }
  }
}
</style>
